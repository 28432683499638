import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "grid-system"
    }}>{`Grid System`}</h1>
    <p>{`Supporting mobile first design standards and following `}<a parentName="p" {...{
        "href": "/components/media-breakpoints",
        "title": "Design Standards, Media Breakpoints"
      }}>{`media breakpoint`}</a>{` rules.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <p><strong parentName="p">{`(XL)`}</strong>{` – Desktop`}</p>
    <p>{`12 column grid with a Main Content container with a maximum width of 1180 pixels. 40px gutter, 62px grid column, no gutter on outside`}</p>
    <p><strong parentName="p">{`(LG)`}</strong>{` – Small Desktop or Large / Horizontal Orientation Tablet `}</p>
    <p>{`12 column grid with a Main Content container with a maximum width of 1180 pixels`}</p>
    <p><strong parentName="p">{`(MD)`}</strong>{` – Vertical Orientation Tablet or Horizontal Orientation / Large Mobile `}</p>
    <p>{`8 column grid with a Main Content container with a maximum width of 960 pixels, 40px? 30? 64px, 26px inner padding, gutter on outside of 20px grid column`}</p>
    <p><strong parentName="p">{`(SM)`}</strong>{` – Vertical Orientation Mobile or Horizontal Orientation Small Mobile`}</p>
    <p>{`4 column grid with a Main Content container with a minimum width of 376 pixels, TBD`}</p>
    <p><strong parentName="p">{`(XS)`}</strong>{` – Vertical Orientation Small Mobile`}</p>
    <p>{`2 column grid with a Main Content container with a maximum width of 375 pixels, TBD`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      